<template>
  <validation-provider
    :name="$attrs.name"
    :rules="rules"
    slim
  >
    <b-field
      slot-scope="{ errors, valid }"
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0], 'is-success': valid }"
      :message="errors"
    >
      <b-upload
        :value="value"
        v-bind="$attrs"
        @input="handleChange"
      >
        <span class="file-cta">
          <b-icon 
            class="file-icon" 
            icon="upload" 
          />
          <span class="file-label">
            <slot>
              Click to upload
            </slot>
          </span>
        </span>
        <span 
          v-if="value" 
          class="file-name"
        >
          {{ value.name }}
        </span>
      </b-upload>
    </b-field>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import "@/common/vee-validate-rules";

export default {
  name: 'UploadWithValidation',

  components: {
    ValidationProvider,
  },

  props: {
    value: {
      type: File,
      default: null,
    },
    rules: {
      type: String,
      default: '',
    },
  },

  methods: {
    handleChange(value) {
      this.$emit("input", value);
    },
  }
};
</script>
