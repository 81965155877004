<template>
  <div>
    <breadcrumb :items="breadcrumbs" />
    <hero>
      New Category
      <router-link 
        slot="right" 
        to="/categories"
        class="button"
      >
        Categories
      </router-link>
    </hero>
    <section class="section is-main-section">
      <card
        title="Add Category"
        icon="plus"
      >
        <validation-observer 
          v-slot="{ handleSubmit }"
          ref="form"
          tag="form"
        >
          <input-with-validation
            v-model="form.title"
            name="title"
            rules="required|max:255"
            label="Title"
            icon="format-title"
            maxlength="255"
            horizontal
            @input="createSlug"
          />
          <input-with-validation
            v-model="form.abbreviation"
            name="abbreviation"
            :rules="{ required: form.navigation === 1, max: 255 }"
            label="Abbreviation"
            icon="content-cut"
            maxlength="255"
            horizontal
          />
          <input-with-validation
            v-model="form.description"
            name="description"
            label="Description"
            type="textarea"
            horizontal
          />
          <input-with-validation
            v-model="form.slug"
            name="slug"
            rules="required|max:255|slug"
            label="Slug"
            icon="link"
            maxlength="255"
            horizontal
          />
          <select-with-validation
            v-model="form.parent_id"
            name="parent_id"
            label="Parent"
            horizontal
          >
            <option value="">
              None
            </option>
            <option
              v-for="item in parentCategories"
              :key="item.distribution_id"
              :value="item.distribution_id"
            >
              {{ item.title }}
            </option>
          </select-with-validation>
          <select-with-validation
            v-if="isDefaultLocale"
            v-model="form.navigation"
            name="navigation"
            label="Navigation"
            horizontal
          >
            <option :value="1">
              Yes
            </option>
            <option :value="0">
              No
            </option>
          </select-with-validation>
          <upload-with-validation
            v-model="form.icon"
            name="icon"
            rules="required|ext:svg,png"
            label="Icon"
            accept=".svg,.png"
            horizontal
          />
          <hr>
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button 
                  type="is-primary"
                  :loading="loading"
                  @click="handleSubmit(createCategory)"
                >
                  Submit
                </b-button>
              </div>
            </b-field>
          </b-field>
        </validation-observer>
      </card>
    </section>
  </div>
</template>

<script>
import slugify from "slugify";
import { mapGetters, mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import buildFormDataMixin from "@/mixins/buildFormDataMixin";
import Breadcrumb from "@/components/Breadcrumb";
import Hero from "@/components/Hero";
import Card from "@/components/Card";
import InputWithValidation from "@/components/Validation/InputWithValidation";
import SelectWithValidation from "@/components/Validation/SelectWithValidation";
import UploadWithValidation from "@/components/Validation/UploadWithValidation";

export default {
  name: 'CategoriesCreate',

  components: {
    ValidationObserver,
    Breadcrumb,
    Hero,
    Card,
    InputWithValidation,
    SelectWithValidation,
    UploadWithValidation,
  },

  mixins: [buildFormDataMixin],

  data() {
    return {   
      breadcrumbs: [
        'Admin', 
        'Create Category',
      ],
      loading: false,
      locale: '',
      form: {
        title: '',
        abbreviation: '',
        description: '',
        slug: '',
        parent_id: '',
        navigation: 0,
        icon: null,
      },
    };
  },

  computed: {
    ...mapGetters('config', ['defaultApiLocale']),
    ...mapGetters('data', ['parentCategories']),
  },

  created() {
    this.locale = this.defaultApiLocale;
  },

  methods: {
    ...mapActions('data', ['addCategory']),

    createSlug(value) {
      this.form.slug = slugify(value, {
        lower: true,
        strict: true,
      });
    },

    createCategory() {
      this.loading = true;
      this.addCategory(this.buildFormData(Object.assign({}, this.form, {
        language: this.locale,
      }))).then(response => {
        this.$buefy.toast.open({
          message: 'Created successfully',
          type: 'is-success',
          position: 'is-bottom-right',
        });
        this.$router.push({ 
          name: 'CategoriesEdit', 
          params: { category: response.data.distribution_id },
        });
      }).catch(err => {
        this.$buefy.toast.open({
          message: err.message,
          type: 'is-warning',
          position: 'is-bottom-right',
        });
        if (err.response) {
          this.$refs.form.setErrors(err.response.data.errors);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
