export default {
  methods: {
    buildFormData(object) {
      const formData = new FormData();
      Object.entries(object).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach(element => {
            formData.append(`${key}[]`, element);
          });
        } else {
          formData.append(key, value);
        }
      });
      return formData;
    },
  },
};
